import "../stylesheets/site/index.scss";
import { $$, $ } from "../libs/bling";
import Cookies from "js-cookie";
import AOS from "aos";
import Rellax from "rellax";

document.addEventListener("DOMContentLoaded", () => {
    console.log("running...");

    /*
    |--------------------------------------------------
    | Cookie Acceptance
    |--------------------------------------------------
    */

    $("#cookie_notice_ok").on("click", () => {
        Cookies.set("cookies_accepted", true);
        $("#cookie_notice").classList.toggle("hidden");
    });

    /*
    |--------------------------------------------------
    | Images Scale
    |--------------------------------------------------
    */

    const matchImg = () => {
        $$(".match-img").forEach(($img) => {
            const w = $img.parentNode.offsetWidth;
            $img.style.height = `${w}px`;
        });
    };

    matchImg();
    window.addEventListener("resize", matchImg);

    AOS.init({
        duration: 800,
    });

    (() => {
        if (!document.querySelector(".rellax")) return;
        new Rellax(".rellax", {
            speed: 1.5,
            center: true,
        });
    })();

    $$(".select-how-section button").on("click", (e) => {
        const { type } = e.target.dataset;
        console.log(type);
        console.log($(`.how-section[data-section=${type}]`));

        $$(".select-how-section button").forEach(($el) =>
            $el.classList.remove("selected")
        );
        $$(".how-section").forEach(($el) => $el.classList.remove("selected"));

        e.target.classList.add("selected");
        $(`.how-section[data-section=${type}]`).classList.add("selected");
    });

    /*
    |--------------------------------------------------
    | Landing Subscribe
    |--------------------------------------------------
    */
    const Subscribe = (e) => {
        e.preventDefault();
        let $form = e.target;
        let $msg = $form.querySelector(".msg");
        let $btn = $form.querySelector("button");
        let email = $form.querySelector("input[type=email]").value;
        $msg.classList.remove("error", "thanks", "show");
        $btn.classList.add("btn-loading");
        $btn.textContent = "Subscribing...";

        fetch($form.action, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document
                    .querySelector('meta[name="csrf-token"]')
                    .getAttribute("content"),
            },
            body: JSON.stringify({ email }),
        })
            .then((r) => r.json())
            .then((data) => {
                $btn.textContent = "Subscribe!";
                $btn.classList.remove("thanks");
                $btn.classList.remove("btn-loading");
                $msg.classList.add("show");
                $msg.classList.add(data.success ? "thanks" : "error");
                if (data.success) {
                    $btn.classList.add("thanks");
                    $btn.textContent = "Subscribed ✓";
                    $msg.textContent =
                        "Thanks for Subscribing! We'll be in touch soon.";
                } else {
                    $msg.textContent = data.data.Message;
                }
            });
    };
    // $("#form").on("submit", Subscribe);
    (() => {
        if (!$(".footer-subscribe-form")) return;
        $(".footer-subscribe-form").on("submit", Subscribe);
    })();

    $$(".with-sub-menu").on("mouseenter", (e) => {
        const $menu = e.target.querySelector(".sub-menu");
        $menu.classList.remove("hidden");
    });

    $$(".with-sub-menu").on("mouseleave", (e) => {
        $$(".sub-menu").forEach((e) => e.classList.add("hidden"));
    });

    $$("[data-county-filter]").on("change", (e) => {
        const { value, dataset } = e.target;
        if (!value) {
            return (window.location.href = `${dataset.url}`);
        }
        window.location.href = `${dataset.url}/location/${value}`;
    });

    $$("[data-area-filter]").on("change", (e) => {
        const county = $("[data-county-filter]").value;

        const { value, dataset } = e.target;
        if (!county) return;
        window.location.href = `${dataset.url}/location/${county}/${value}`;
    });

    $$("[data-sitter-county-filter]").on("change", (e) => {
        const { value, dataset } = e.target;
        if (!value) {
            return (window.location.href = `${dataset.url}`);
        }
        window.location.href = `/sitters${
            dataset.url ? `/${dataset.url}` : ""
        }/${value}`;
    });

    $$("[data-sitter-area-filter]").on("change", (e) => {
        const county = $("[data-sitter-county-filter]").value;

        const { value, dataset } = e.target;
        if (!county) return;
        window.location.href = `/sitters${
            dataset.url ? `/${dataset.url}` : ""
        }/${county}/${value}`;
    });

    $$("[data-filter-sidebar] input[type=checkbox]").on("change", (e) => {
        const checked = e.target.checked;
        const $tick = e.target.closest("li").querySelector("svg");
        console.log($tick);
        $tick.classList.toggle("hidden", !checked);
    });

    $$("[data-filter-sidebar] input[type=radio]").on("change", (e) => {
        const checked = e.target.checked;
        const $ticks = e.target.closest("ul").querySelectorAll("svg");
        const $tick = e.target.closest("li").querySelector("svg");

        [...$ticks].forEach(($el) => $el.classList.add("hidden"));

        $tick.classList.toggle("hidden", !checked);
    });

    $$("[data-filter-toggle]").on("click", () => {
        const $sidebar = $("[data-filter-sidebar]");
        $sidebar.classList.toggle("shadow-lg");
        $sidebar.classList.toggle("-translate-x-full");
    });

    $$("[data-order-by]").on("change", (e) => {
        e.target.closest("form").submit();
    });

    $$("[data-tag-trigger]").forEach(($el) => {
        $el.addEventListener("mouseenter", () => {
            $el.querySelector("[data-tag]").style.display = "block";
        });
        $el.addEventListener("mouseleave", () => {
            $el.querySelector("[data-tag]").style.display = "none";
        });
    });

    /*
    |--------------------------------------------------
    | Apply Window
    |--------------------------------------------------
    */

    class Apply {
        constructor($el) {
            if (!$el.querySelector("[data-apply-btn]")) {
                return;
            }
            this.$toggles = [...$el.querySelectorAll("[data-apply-toggle]")];
            this.$applybtn = $el.querySelector("[data-apply-btn]");
            this.$appliedbtn = $el.querySelector("[data-applied-btn]");
            this.$modal = $el.querySelector("[data-apply-modal]");
            this.$form = $el.querySelector("[data-apply-form]");
            this.$success = $el.querySelector("[data-success-message]");
            this.$btn = this.$form.querySelector("[data-apply-submit]");
            this.$error = this.$form.querySelector("[data-error-message]");

            this.$toggles.forEach(($btn) => {
                $btn.addEventListener("click", () =>
                    this.$modal.classList.toggle("hidden")
                );
            });

            this.$form.addEventListener("submit", (e) => this.submitForm(e));
        }

        setLoading(state) {
            this.$btn
                .querySelector("[data-text]")
                .classList.toggle("hidden", state);
            this.$btn
                .querySelector("[data-loading]")
                .classList.toggle("hidden", !state);
        }

        setError(visible, message = "") {
            this.$error.textContent = message;
            this.$error.classList.toggle("hidden", !visible);
        }

        submitForm(e) {
            e.preventDefault();
            this.setLoading(true);
            this.setError(false);

            if (!this.$form.querySelector("textarea").value.trim()) {
                this.setLoading(false);
                return this.setError(true, "You must include a cover letter");
            }

            fetch(this.$form.action, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document
                        .querySelector('meta[name="csrf-token"]')
                        .getAttribute("content"),
                },
                body: JSON.stringify({
                    cover_letter: this.$form.querySelector("textarea").value,
                }),
            })
                .then((resp) => resp.json())
                .then((resp) => {
                    this.setLoading(false);
                    if (resp.success) {
                        return this.showSuccess();
                    }
                    if (resp.error) {
                        return this.setError(true, resp.error);
                    }
                    return this.setError(true, resp.message.cover_letter);
                })
                .catch((e) => {
                    console.log(e);
                    this.setLoading(false);
                    this.setError(true, e.response.message);
                });
        }

        showSuccess() {
            this.$form.classList.add("hidden");
            this.$success.classList.remove("hidden");
            this.$success.classList.add("flex");

            this.$applybtn.classList.remove("inline-block");
            this.$applybtn.classList.add("hidden");

            this.$appliedbtn.classList.remove("hidden");
            this.$appliedbtn.classList.add("inline-flex");
        }
    }

    $$("[data-apply]").forEach(($el) => new Apply($el));

    /*
    |--------------------------------------------------
    | Message Modal
    |--------------------------------------------------
    */

    class Message {
        constructor($el) {
            this.$toggles = [...$el.querySelectorAll("[data-message-toggle]")];
            this.$messagebtn = $el.querySelector("[data-message-btn]");
            this.$connectedMsgs = [
                ...$el.querySelectorAll("[data-connected-message]"),
            ];
            this.$modal = $el.querySelector("[data-message-modal]");
            this.$form = $el.querySelector("[data-message-form]");
            this.$success = $el.querySelector(
                "[data-conversation-success-message]"
            );
            this.$btn = this.$form?.querySelector("[data-message-submit]");
            this.$error = this.$form?.querySelector("[data-error-message]");

            this.$connectedLinks = [
                ...$el.querySelectorAll("[data-connected-link]"),
            ];

            this.$toggles.forEach(($btn) => {
                $btn.addEventListener("click", () =>
                    this.$modal.classList.toggle("hidden")
                );
            });

            this.$form?.addEventListener("submit", (e) => this.submitForm(e));
        }

        setLoading(state) {
            this.$btn
                .querySelector("[data-text]")
                .classList.toggle("hidden", state);
            this.$btn
                .querySelector("[data-loading]")
                .classList.toggle("hidden", !state);
        }

        setError(visible, message = "") {
            this.$error.textContent = message;
            this.$error.classList.toggle("hidden", !visible);
        }

        submitForm(e) {
            e.preventDefault();

            this.setError(false);

            if (
                this.$form.querySelector("textarea[name=body]").value.length <=
                0
            ) {
                return this.setError(true, "A message is required");
            }

            this.setLoading(true);

            fetch(this.$form.action, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document
                        .querySelector('meta[name="csrf-token"]')
                        .getAttribute("content"),
                },
                body: JSON.stringify({
                    body: this.$form.querySelector("textarea[name=body]").value,
                    user_id: this.$form.querySelector("input[name=user_id]")
                        .value,
                }),
            })
                .then((resp) => resp.json())
                .then((resp) => {
                    console.log(resp);
                    this.setLoading(false);
                    if (resp.success) {
                        this.$connectedLinks.forEach((link) => {
                            link.href = `/dashboard/inbox?view=${resp.conversation.id}`;
                        });
                        return this.showSuccess();
                    }
                    if (resp.error) {
                        return this.setError(true, resp.error);
                    }
                    return this.setError(true, resp.message);
                })
                .catch((e) => {
                    console.log(e);
                    this.setLoading(false);
                    this.setError(true, e.response.message);
                });
        }

        showSuccess() {
            this.$form.classList.add("hidden");
            this.$success.classList.remove("hidden");
            this.$success.classList.add("flex");

            this.$connectedMsgs.forEach((item) => {
                item.classList.toggle("hidden");
            });
        }
    }

    $$("[data-message]").forEach(($el) => new Message($el));

    $$("[data-mnav-toggle]").on("click", () => {
        const $nav = $("[data-mnav]");
        $nav.classList.toggle("shadow-xl");
        $nav.classList.toggle("translate-x-full");
    });

    $$("[data-mdropdown-toggle]").on("click", (e) => {
        e.target
            .closest("li")
            .querySelector(".mdropdown")
            .classList.toggle("open");
    });

    /*
    |--------------------------------------------------
    | Search Form
    |--------------------------------------------------
    */

    $$("[data-search-type-toggle] button").on("click", (e) => {
        const { type } = e.currentTarget.dataset;
        $$("[data-search-type-toggle] button").forEach(($el) =>
            $el.classList.remove("search-toggle-active")
        );
        e.currentTarget.classList.add("search-toggle-active");

        $("[data-search-type-text]").textContent = type;
        $("[data-search-type]").value = type;
    });

    $$("[data-county-search-select]").on("change", (e) => {
        const { value } = e.currentTarget;
        const $el = $("[data-townland-search-select]");
        fetch(`/api/v1/search/counties?id=${value}`)
            .then((resp) => resp.json())
            .then((resp) => {
                const options = [{ name: "Everywhere", slug: "" }, ...resp];

                $el.innerHTML = ``;

                options.forEach((item) => {
                    console.log(item);
                    const opt = document.createElement("option");
                    opt.textContent = item.name;
                    opt.value = item.slug;
                    $el.appendChild(opt);
                });
            });
    });

    $$("[data-show-upgrade]").on("click", () => {
        const display = $("[data-upgrade-modal]").style.display;
        $("[data-upgrade-modal]").style.display =
            display === "flex" ? "none" : "flex";
    });

    // (() => {
    //     setTimeout(() => {
    //         $(".tw-flash-message").classList.add("-translate-y-full");
    //     }, 3000);
    // })();
});
